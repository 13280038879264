<template>
  <div v-if="!currentPath">
    <div class="header pop16">
      <span class="ama60">Collectie</span>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec laoreet
        diam nisl, vel accumsan nibh laoreet ut. Vivamus placerat arcu justo, ac
        eleifend massa elementum eu. Vestibulum ac quam magna. Vestibulum eu
        lacus ac augue porttitor euismod. Maecenas sit amet fringilla mi.
        Pellentesque malesuada sem urna, id pretium libero tempor rutrum. Mauris
        leo magna, luctus ac sodales a, scelerisque in felis. Pellentesque
        accumsan massa justo, in pellentesque quam pellentesque sit amet. Aenean
        commodo, libero a luctus interdum, augue mi posuere leo, sed
        sollicitudin lectus mauris vitae neque. Maecenas laoreet et quam at
        imperdiet. Donec eu risus eget felis laoreet dapibus. Etiam sit amet
        molestie metus.
      </p>
    </div>
    <div class="action">
      <div class="p-d-flex p-jc-between">
        <div class="facet">
          <MultiSelect
            v-model="selectedfacets"
            :options="groupedfacets"
            optionLabel="label"
            optionGroupLabel="label"
            optionGroupChildren="items"
            placeholder="filter"
            @change="onFilter"
          ></MultiSelect>
        </div>
        <div>
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText
              type="text"
              placeholder="Zoek"
              v-model="searchterm"
              @keyup.enter="onSearch"
            />
          </span>
        </div>
      </div>
    </div>
    <div
      class="grid"
      v-masonry
      item-selector=".grid-item"
      column-width="197"
      gutter="20"
    >
      <div
        v-masonry-tile
        class="grid-item"
        v-for="record in records"
        :key="record"
      >
        <ccCard :record="record.data.record" v-if="record" />
      </div>
      <div id="next" />
    </div>
  </div>
  <div v-else>
    <ccRecordVue :path="currentPath" type="5" />
  </div>
</template>

<script>
import recordService from "../services/record_service"
import ccRecordVue from "./cc_record"
import MultiSelect from "primevue/multiselect"
import ccCard from "../components/cc_card"

export default {
  components: {
    ccRecordVue,
    MultiSelect,
    ccCard,
  },
  watch: {
    $route: "reload",
  },
  data() {
    return {
      records: null, //{ nbrecords: 0, totalrecords: 0, records: null },
      currentPath: this.$router.currentRoute.value.params.recordId,
      groupedfacets: null,
      selectedfacets: null,
      rawQuery: this.$route.query && this.$route.query.search ? "*=" + this.$route.query.search : "*=*",
      filteredQuery: "",
      searchterm: this.$route.query && this.$route.query.search ? this.$route.query.search : "",
      recstart: 0,
      recnumber: 15,
      isLoading: false,
      truerecords: null,
      totalrecords: 0,
      nbrecords: 0,
    }
  },
  recordservice: null,
  created() {
    this.initSearch()
    this.recordservice = new recordService()
  },
  mounted() {
    if (!this.currentPath) {
      window.addEventListener("scroll", this.onScroll)
      this.onFilter(null)
      document.body.parentElement.classList.add("green_bg")
      document.body.classList.add("green_bg")
    }
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll)
  },
  methods: {
    reload() {
      this.currentPath = this.$router.currentRoute.value.params.recordId
    },
    getTitle(title) {
      if (title.length > 20) return title.substring(0, 20) + "..."
      return title
    },
    search() {
      var t = this
      t.isLoading = true
      this.recordservice
        .search(this.filteredQuery, this.recstart, this.recnumber)
        .then((data) => {
          console.info(
            `fetching records ${this.recstart}-${this.recstart +
              this.recnumber} for query ${this.filteredQuery}`
          )
          var recs = JSON.parse(data)
          t.totalrecords =
            recs["collectionConnection-resultset"].request.count.value
          if (
            t.totalrecords > 0 &&
            recs["collectionConnection-resultset"].records.record
          ) {
            var k = Object.keys(
              recs["collectionConnection-resultset"].records.record
            )
            t.nbrecords = k ? k.length : 0
            if (t.nbrecords > 0) {
              if (t.truerecords) {
                t.truerecords = t.truerecords.concat(
                  recs["collectionConnection-resultset"].records.record
                )
              } else {
                t.truerecords =
                  recs["collectionConnection-resultset"].records.record
              }
              t.records = t.truerecords
              //console.info(t.records)
            }
          } else {
            // t.records = []
          }
          t.getFacets(
            recs["collectionConnection-resultset"].records.predict_query
          )
          t.isLoading = false
        })
      //}
    },
    getFacets(predict_query) {
      this.groupedfacets = [
        { label: "Categorieen", items: [] },
        { label: "Standplaats", items: [] },
      ]

      predict_query.forEach((q) => {
        if (q._attributes.query.includes(";categorie=Bibliotheek)"))
          this.groupedfacets[0].items.push({
            label: "Bibliotheek (" + q.value + ")",
            value: "categorie=Bibliotheek",
          })
        if (q._attributes.query.includes(";categorie=Beeldarchief)"))
          this.groupedfacets[0].items.push({
            label: `Beeldarchief (${q.value})`,
            value: "categorie=Beeldarchief",
          })
        if (q._attributes.query.includes(";categorie=Documenten)"))
          this.groupedfacets[0].items.push({
            label: `Documenten (${q.value})`,
            value: "categorie=Documenten",
          })
        if (q._attributes.query.includes(";categorie=Geluidarchief)"))
          this.groupedfacets[0].items.push({
            label: `Geluidarchief (${q.value})`,
            value: "categorie=Geluidarchief",
          })
        if (q._attributes.query.includes(";categorie=Ingelijste prenten)"))
          this.groupedfacets[0].items.push({
            label: `Ingelijste prenten (${q.value})`,
            value: "categorie=Ingelijste prenten",
          })
        if (q._attributes.query.includes(";categorie=Voorwerpen)"))
          this.groupedfacets[0].items.push({
            label: `Voorwerpen (${q.value})`,
            value: "categorie=Voorwerpen",
          })
        if (q._attributes.query.includes(";standplaats=KITLV Leiden)"))
          this.groupedfacets[1].items.push({
            label: `KITLV Leiden (${q.value})`,
            value: "standplaats=KITLV Leiden",
          })
        if (
          q._attributes.query.includes(";standplaats=Museum Bronbeek Arnhem)")
        )
          this.groupedfacets[1].items.push({
            label: `Museum Bronbeek Arnhem (${q.value})`,
            value: "standplaats=Museum Bronbeek Arnhem",
          })
        if (
          q._attributes.query.includes(
            ";standplaats=Tropenmuseum (NMvW) Amsterdam)"
          )
        )
          this.groupedfacets[1].items.push({
            label: `Tropenmuseum (NMvW) Amsterdam (${q.value})`,
            value: "standplaats=Tropenmuseum (NMvW) Amsterdam",
          })
      })
    },
    onFilter(event) {
      console.info(this.$route.query)
      this.filteredQuery = this.rawQuery
      if (event) {
        this.initSearch()
        event.value.forEach((c) => {
          this.filteredQuery = `and(${c.value};${this.filteredQuery})`
        })
      }
      this.search()
    },
    onSearch(event) {
      if (event) {
        //console.info(this.searchterm)
        this.rawQuery = `*=${this.searchterm}`
        this.initSearch()
        this.selectedfacets = null
        this.onFilter(null)
      }
    },
    onScroll() {
      if (
        !this.currentPath &&
        window.innerHeight + window.scrollY >= document.body.offsetHeight
      ) {
        if (!this.isLoading) {
          this.recstart += this.recnumber
          this.search()
          //this.onFilter(null)
        }
      }
    },
    initSearch() {
      this.records = null
      this.truerecords = null
      this.recstart = 0
    },
  },
}
</script>

<style scoped>
.header {
  width: 630px;
  position: absolute;
  top: 161px;
  left: 90px;
}
.data {
  position: sticky;
  margin-top: 600px;
  margin-left: auto;
  margin-right: auto;
  width: 1020px;
}
.grid-item {
  margin-bottom: 20px;
  width: 197px;
}
.grid {
  /* top: 600px; */
  margin: 97px;
}
.more {
  color: #201022;
}
.p-multiselect {
  min-width: 196px;
  font-family: Poppins-Regular;
  font-size: 18px;
  font-weight: 100;
  border: none;
}
.p-multiselect >>> .p-multiselect-label {
  color: white;
  background-color: #ef5e33;
}
.p-multiselect >>> .p-multiselect-label.p-placeholder {
  color: white !important;
}
.p-multiselect >>> .p-multiselect-item {
  font-family: Amaranth-Regular;
  font-size: 22px;
}
.p-multiselect >>> .p-multiselect-trigger {
  background-color: #ef5e33 !important;
}
.p-multiselect >>> .pi-chevron-down {
  /*  content: "\e900";*/
  color: white !important;
}
.action {
  margin-top: 530px;
  margin-left: 97px;
  margin-right: 97px;
}
.p-multiselect >>> .p-inputtext {
  border-radius: 0;
}
</style>
