<template>
  <div class="record">
    <div class="backlink ama18">
      <a @click="goback()"
        ><img src="@/assets/images/leftarrow.svg" />{{ $route.name }}</a
      >
    </div>
    <div class="topimage p-d-flex p-jc-center">
      <img :src="imgsrc" class="loading" />
    </div>
    <div class="data">
      <div class="keywords ama18">
        <span v-if="record.dated" class="keyword">
          {{ record.dated.value }}
        </span>
        <span v-if="record.standplaats" class="keyword">
          {{ record.standplaats.value }}
        </span>
      </div>
      <div v-if="record.title" class="title ama60">
        {{ record.title.value }}
      </div>
      <div v-if="record.subtitle" class="subtitle ama40">
        {{ record.subtitle.value }}
      </div>
      <div v-if="record.description" class="description pop24">
        <span v-html="record.description.value" />
      </div>
    </div>
  </div>
</template>

<script>
import recordService from "../services/record_service"
import config from "../../config"

export default {
  props: ["path", "type"],
  data() {
    return {
      record: {},
      windowWidth: (this.windowWidth = window.innerWidth),
      keywords: {},
      medias: [],
      imgsrc: "",
    }
  },
  recordservice: null,
  created() {
    this.recordservice = new recordService()
  },
  mounted() {
    var t = this
    this.recordservice.getBySlug(this.path, this.type).then((data) => {
      var r = JSON.parse(data)
      if (
        r["collectionConnection-resultset"].records.record &&
        r["collectionConnection-resultset"].records.record.data.record
      )
        t.record =
          r["collectionConnection-resultset"].records.record.data.record
      var myImage = new Image()
      if (t.record.image) {
        myImage.src = `${config.iwib.server}/cci/${t.windowWidth}/590/${t.record.image.value}?background=201022&fetch=false`
      } else {
        myImage.src = "@/assets/images/_noimage.png"
      }
      myImage.onload = () => {
        this.imgsrc = myImage.src
      }
      this.imgsrc = require("@/assets/images/image_loader_green_590.svg")
    })
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth
    })
    document.body.parentElement.classList.remove("green_bg")
    document.body.classList.remove("green_bg")
    document.body.parentElement.classList.add("purple_bg")
    document.body.classList.add("purple_bg")
  },
  methods: {
    goback() {
      document.body.parentElement.classList.remove("purple_bg")
      document.body.classList.remove("purple_bg")
      document.body.parentElement.classList.add("green_bg")
      document.body.classList.add("green_bg")
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped>
/* .loading {
  background: transparent url("~@/assets/images/image_loader_green.gif") center
    no-repeat;
}
 */
.record {
  width: 100%;
  background-color: #201022;
  color: white;
}
.topimage {
  height: 590px;
  margin-top: 100px;
}
.data {
  margin-left: 294px;
  margin-top: 35px;
  margin-right: 294px;
}
.title {
  margin-top: 20px;
}
.keyword {
  background-color: #67a178;
  height: 50px;
  width: fit-content;
  padding: 11px;
  cursor: pointer;
  margin: 30px 20px 20px 0px;
}
.description {
}
.backlink {
  position: absolute;
  top: 104px;
  left: 69px;
  cursor: pointer;
}
.backlink img {
  margin-right: 10px;
}
</style>
